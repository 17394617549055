import React from 'react';
import {Link} from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ErrorBg from '../components/ErrorBg';
import Fade from 'react-reveal/Fade';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="w-100 vh-100 db bg-black relative overflow-hidden">
      <ErrorBg />
      <Fade>
        <div className="w-100 h-100 absolute top-0 left-0 flex items-center">
          <div className="mw8 white center tc">
            <div className="f2 f1-l g-medium lh-copy mb3">Page not found</div>
            <div className="f5 f-intro-l">
              <p>Looks like something went wrong on our end.</p> <p>Head back to our homepage.</p>
              <Link to="/" className="link yellow button g-medium ttu f5 tracked flex items-center center mt4 justify-center mt4">
                Go Back
              </Link>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  </Layout>
);

export default NotFoundPage;
